.select-custom .dropdown-toggle:after {
    content: none;
}
.select-custom {
    vertical-align: middle;
    top: 32px;
}

.select-custom .btn {
    border-width: 2px;
    font-weight: 600;
    font-size: 0.8571em;
    line-height: 1.35em;
    text-transform: uppercase;
    border: none;
    margin: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    background-color: #66615B;
    color: #FFFFFF;
    -webkit-transition: all 150ms linear;
    transition: all 150ms linear;
}
.div_titulo {
    border-top: 1px solid #000;
}
.div_titulo h1 {
    text-align: left;
    margin-top: -10px;
    margin-left: 10px;
    height: 20px;
    line-height: 20px;
    font-size: 15px;
}
.div_titulo h1 span {
    background-color: #fff;
}
.input_button_sm {
    width: 50%;
    display: inline;
    margin-right: 5px;
}
.rotar_derecha {
    /* idéntico a rotateZ(45deg); */
    transform: rotate(138deg);
    font-size: 26px;
}
.rotar_izquierda {
    /* idéntico a rotateZ(45deg); */
    transform: rotate(223deg);
    font-size: 26px;
}
.modal-lg {
    max-width: 90%;
}
.btn_catalogos {
    margin: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
}
.btn_catalogos.ajuste_catalgo{
    margin-top: 35px;
}
.sidebar-wrapper a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: #FFFFFF !important;
    text-decoration: none;
}
.dropdown-toggle:after {
    display: inline-block;
    width: 0;
    height: 0;
    float: right;
    margin-top: 10px;
    vertical-align: middle;
    content: "";
    border-top: .7em solid;
    border-right: .7em solid transparent;
    border-left: .7em solid transparent;
    transition: transform 1s ease;
}
.dropdown-toggle.abierto:after {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.menu_expandible {
    padding-left: 47px;
}
.articulos .form-control {
    font-size: 10px;
    padding: 3px !important;
}
.form-group label {
    /*font-size: 10px;*/
    margin-bottom: 5px;
    /*color: #9A9A9A;*/
}
.pl20 {
    padding-left: 20px;
}
.pl25 {
    padding-left: 25px;
}
.pl30 {
    padding-left: 30px;
}
.col_titulo {
    background-color: #0304f9ba;
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.col_titulo label {
    font-size: 0.8571em;
    margin: 0px;
    color: white !important;
}
.fondo_label {
    background-color: aliceblue;
}
.fondo_label label {
    font-size: 0.8571em;
    margin: 0px;
    margin-top: 5px;
    padding-left: 5px;
}
@media screen and (min-width: 991px) {
    .display_block_menu {
        display: block !important;
    }
    .navbar .navbar-toggler-bar {
        display: block;
        position: relative;
        width: 22px;
        height: 1px;
        border-radius: 1px;
        background: #66615B;
    }
    .navbar .navbar-toggler-bar+.navbar-toggler-bar {
        margin-top: 7px;
    }
    .nav-open .main-panel {
        right: 0;
        /* -webkit-transform: translate3d(260px, 0, 0);
    transform: translate3d(260px, 0, 0);*/
        width: calc( 100% - 260px);
    }
    .main-panel {
        width: 100%;
    }
    .nav-open .sidebar {
        -webkit-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);
    }
    .sidebar, .bootstrap-navbar {
        position: fixed;
        display: block;
        top: 0;
        height: 100%;
        width: 260px;
        right: auto;
        left: 0;
        z-index: 1032;
        visibility: visible;
        overflow-y: visible;
        padding: 0;
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -webkit-transform: translate3d(-260px, 0, 0);
        transform: translate3d(-260px, 0, 0);
    }
}